.react-tabs {
  -webkit-tap-highlight-color: transparent;
}

.react-tabs__tab-list {
  border-bottom: 1px solid #aaa;
  margin: 0 0 10px;
  padding: 0;
  display: flex;
}

.react-tabs__tab {
  display: inline-block;
  border: 1px solid transparent;
  border-bottom: none;
  bottom: -1px;
  position: relative;
  list-style: none;
  padding: 6px 12px;
  cursor: pointer;
}

.react-tabs__tab--selected {
  background: #1132EA;
  border-color: #aaa;
  color: black;
  border-radius: 5px 5px 0 0;
}

.react-tabs__tab--disabled {
  color: GrayText;
  cursor: default;
}

.react-tabs__tab:focus {
  box-shadow: 0 0 5px hsl(208, 99%, 50%);
  border-color: hsl(208, 99%, 50%);
  outline: none;
}

.react-tabs__tab:focus:after {
  content: "";
  position: absolute;
  height: 5px;
  left: -4px;
  right: -4px;
  bottom: -5px;
  background: #fff;
}

.react-tabs__tab-panel {
  display: none;
}

.react-tabs__tab-panel--selected {
  display: block;
}
/*.react-tabs__tab{color: #DCDCDC !important;}*/

/* ProductSection.css */
.product-list {
  margin: '10px';
  border: '1px solid #949494';
  padding: '10px';
  background-color: #eae7e7 ;
}
.product-section {
  margin: 20px;
  padding: 20px;
  border: 1px solid #949494;
  border-radius: 8px;
  background-color: #eae7e7;
  max-width: 720px; /* Increased max-width to accommodate longer text */
}

.product-section select,
.product-section .ant-radio-group,
.product-section .input-group {
  margin: 20px 0;
}

.product-section .ant-radio-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.product-section .ant-radio-group .ant-radio-wrapper {
  white-space: nowrap; /* Prevent text wrapping */
  
  text-overflow: ellipsis; /* Add ellipsis if text overflows */
}

.product-section .input-group {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.product-section .input-group input {
  margin-left: 10px;
  width: 150px;
}

.product-section .input-group label {
  width: 150px;
}

.product-section .total-amount {
  font-weight: bold;
  color: #333;
}

.product-section .paypal-button {
  margin-top: 20px;
  text-align: center;
}
.product-select {
  background: #fff !important;
  padding: 5px;
  border: 1px solid #ddd;
}
.ant-radio-inner {
  width: 20px !important;
  height: 20px !important;
  border: 1px solid #bbb !important ;
}
@media (max-width:767px) {
  .price-paln {
    margin: 10px;
    border: 1px solid #949494;
    padding: 10px;
    background: #eae7e7;
    width: 100%;
  }
  .product-section .input-group label {
    width: 100px;
  }
}
